import { EMAIL, MOBILEPHONE } from "@/models/channelTypes.js";

// eslint-disable-next-line no-useless-escape
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// eslint-disable-next-line no-useless-escape
const PHONE_REGEX = /^(?:\(?\+?48)?(?:[-\.\(\)\s]*(\d)){9}\)?$/;

export function emailValidator(value) {
  return EMAIL_REGEX.test(value);
}
export function phoneValidator(value) {
  return PHONE_REGEX.test(value);
}

export function momentValidator(value) {
  return (
    typeof value === "object" &&
    (value === null || value._isAMomentObject === true)
  );
}

export function validateComplaintBeforeSave(complaint) {
  if (!complaint) {
    return false;
  }

  if (!complaint.title || complaint.title.trim() === "") {
    return false;
  }

  if (!complaint.category) {
    return false;
  }

  if (!complaint.subCategory) {
    return false;
  }

  if (!complaint.description || complaint.description.trim() === "") {
    return false;
  }

  if (!complaint.caller) {
    return false;
  }

  if (!complaint.owner) {
    return false;
  }

  if (!complaint.priority) {
    return false;
  }

  if (!complaint.source) {
    return false;
  }

  return true;
}

function isValueDuplicated(items, value) {
  const textItems = items.map((item) => item.text);

  return textItems.some(
    (item) =>
      item === value &&
      textItems.indexOf(value) !== textItems.lastIndexOf(value)
  );
}

function hasValue(value) {
  return Boolean(value);
}

function isEmailOrPhoneProvided(emails, phones) {
  const isEmailProvided =
    Array.isArray(emails) && emails[0] ? emails[0].text.trim() !== "" : false;

  const isPhoneProvided =
    Array.isArray(phones) && phones[0] ? phones[0].text.trim() !== "" : false;

  return isEmailProvided || isPhoneProvided;
}

export function validateEmailOrPhoneCustomList(items, type, component) {
  const errors = [];
  const itemsToCheckIfAlreadyUsed = [];
  const vm = component;

  if (Array.isArray(items)) {
    items.forEach((item, index) => {
      if (!hasValue(item.text)) {
        errors[index] = vm.$t("rules.required");
      } else if (type === EMAIL && !emailValidator(item.text)) {
        errors[index] = vm.$t("rules.email");
      } else if (type === MOBILEPHONE && !phoneValidator(item.text)) {
        errors[index] = vm.$t("rules.mobilePhone");
      } else if (isValueDuplicated(items, item.text)) {
        errors[index] =
          type === EMAIL ? vm.$t("rules.sameMail") : vm.$t("rules.samePhone");
      } else if (item.isMain && item.isPreferred) {
        itemsToCheckIfAlreadyUsed.push({ index: index, value: item.text });
      }
    });
  }

  return { errors, itemsToCheckIfAlreadyUsed };
}

export function validateContactBeforeSave(
  contact,
  currentErrorMessages,
  componet
) {
  const vm = componet;
  let isValidationError = false;
  const errors = { ...currentErrorMessages };

  if (!contact.firstname || contact.firstname.trim() === "") {
    errors.firstname = vm.$t("rules.required");
    isValidationError = true;
  }

  if (!contact.surname || contact.surname.trim() === "") {
    errors.surname = vm.$t("rules.required");
    isValidationError = true;
  }

  if (!contact.type) {
    errors.type = vm.$t("rules.required");
    isValidationError = true;
  }

  if (!isEmailOrPhoneProvided(contact.emails, contact.phones)) {
    isValidationError = true;
    const emailErrors = currentErrorMessages.emails
      ? [...currentErrorMessages.emails]
      : [];
    const phoneErrors = currentErrorMessages.phones
      ? [...currentErrorMessages.phones]
      : [];

    emailErrors[0] = vm.$t("rules.emailOrPhoneRequire");
    phoneErrors[0] = vm.$t("rules.emailOrPhoneRequire");

    errors.emails = emailErrors;
    errors.phones = phoneErrors;
  }

  return { isValidationError, errors };
}
