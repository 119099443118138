<template>
  <DataGrid
    v-model="selectedContacts"
    :card="card"
    :display.sync="display"
    :minimumCardWidth="425"
    @click="showDetails"
    :search="searchText"
    @update:search="searchHandler"
    :items="contacts"
    :pagination="pagination"
    @update:pagination="getContacts"
    :isLoading="isLoading"
  >
    <template #header>
      <div class="header">
        <OrderPicker :options="orderByOptions" v-model="orderBy" />
        <FiltersPicker :categories="filterOptions" v-model="filters" />
      </div>
    </template>

    <template #toolbar>
      <SelectionPicker
        :options="types"
        :disabled="isLoading"
        v-model="selectedTypes"
      />
      <v-spacer></v-spacer>
      <v-btn
        v-can:CRM_WRITE
        large
        icon
        text
        class="toolbar-button"
        :to="{ name: 'newContact' }"
      >
        <IconBox :tooltip="$t('newContact.add')" color="iconButton">
          stem-personPlus
        </IconBox>
      </v-btn>
      <v-btn
        v-if="!selectedContacts || selectedContacts.length === 0"
        large
        icon
        text
        :ripple="false"
        class="toolbar-button"
      >
        <IconBox color="disabled"> stem-delete </IconBox>
      </v-btn>
      <v-btn
        v-else-if="!canDeleteSelectedItems"
        large
        icon
        text
        :ripple="false"
        class="toolbar-button"
      >
        <IconBox :tooltip="$t('contacts.unableToDelete')" color="disabled">
          stem-delete
        </IconBox>
      </v-btn>
      <v-btn
        v-else-if="userRestrictions.some((item) => item.id === 402)"
        large
        icon
        text
        class="toolbar-button"
        :disabled="!canDeleteSelectedItems"
        @click="tryDelete"
      >
        <IconBox :tooltip="$t('newContact.delete')" color="iconButton">
          stem-delete
        </IconBox>
      </v-btn>
      <ConfirmModal
        v-model="isConfirmDeleteModalShown"
        :confirmText="
          $t('global.confirmTextCount', {
            count: selectedContacts.length,
          })
        "
        @confirm="deleteContacts"
        @cancel="isConfirmDeleteModalShown = false"
      >
      </ConfirmModal>
    </template>
  </DataGrid>
</template>

<script>
import { mapGetters } from "vuex";

import CrmCustomersService from "../services/CrmCustomersService.js";

import { mapProps } from "@/store";
import { EmitError, EmitSuccess } from "@/eventBus";
import { ContactTypes, getContactTypeColors } from "@/models/contactsHelper";
import { EMAIL, MOBILEPHONE } from "@/models/channelTypes.js";

import DataGrid from "../components/Shared/DataGrid";
import FiltersPicker from "../components/Shared/FiltersPicker";
import OrderPicker from "../components/Shared/OrderPicker";
import SelectionPicker from "../components/Shared/SelectionPicker";
import ContactCard from "../components/Contacts/ContactCard";
import ConfirmModal from "../components/Shared/ConfirmModal";

export default {
  name: "ContactsList",
  components: {
    DataGrid,
    // DateField,
    FiltersPicker,
    OrderPicker,
    SelectionPicker,
    ConfirmModal,
  },
  created() {
    Promise.all([this._getCrmTypes()]).then(() => {
      this.getContacts();
    });
  },
  data() {
    return {
      isConfirmDeleteModalShown: false,
      isLoading: false,
      card: ContactCard,
      contacts: [],
      types: [],
      orderByOptions: ["SURNAME", "INSERTDATE", "MODIFICATIONDATE", "TYPE"],
      filterOptions: [
        {
          type: "datePicker",
          name: this.$t("complaints.creationDate"),
          value: "creationDate",
          options: [
            {
              name: this.$t("common.from"),
              value: "from",
            },
            {
              name: this.$t("common.to"),
              value: "to",
              endOfDay: true,
            },
          ],
        },
        {
          type: "datePicker",
          name: this.$t("common.date.modify"),
          value: "modificationDate",
          options: [
            {
              name: this.$t("common.from"),
              value: "from",
            },
            {
              name: this.$t("common.to"),
              value: "to",
              endOfDay: true,
            },
          ],
        },
      ],
      currentDate: this.$moment(),
      searchText: "",
      searchTextName: "",
    };
  },
  watch: {
    filtersDto: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;
        this.pagination.page = 1;
        this.getContacts();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("settings", ["isMobile", "getUserRestrictions"]),
    ...mapProps(
      [
        "orderBy",
        "insertDateFrom",
        "insertDateTo",
        "filters",
        "selectedTypes",
        "display",
        "pagination",
        "selectedContacts",
      ],
      {
        options: "contactOptions",
        action: "saveContactOption",
      }
    ),
    filtersDto() {
      return {
        search: this.searchText,
        types: this.selectedTypes.map((t) => t.code),
        orderBy: this.orderBy.name,
        orderDesc: this.orderBy.desc,
        ...this.formatFilters(),
      };
    },
    maxDateFrom() {
      return this.insertDateTo ? this.$moment(this.insertDateTo) : null;
    },
    minDateTo() {
      return this.insertDateFrom ? this.$moment(this.insertDateFrom) : null;
    },
    userRestrictions() {
      return this.getUserRestrictions ? this.getUserRestrictions.global : [];
    },
    canDeleteSelectedItems() {
      return this.selectedContacts.every((item) => item.canDelete);
    },
  },
  methods: {
    getContacts(pagination) {
      if (this.isLoading) return;
      this.isLoading = true;
      return CrmCustomersService.GetCrmCustomers({
        ...this.filtersDto,
        ...this._getPagination(pagination),
      })
        .then((res) => {
          res.data.forEach((d) => (d.error = ""));
          res.data.forEach((d) => {
            d.color = getContactTypeColors(d.type);

            d.contacts.forEach((contact) => {
              if (contact.id === MOBILEPHONE) {
                d.phone = contact.text;
              } else if (contact.id === EMAIL) {
                d.email = contact.text;
              }
            });
          });
          this.contacts = res.data;
          this.pagination = {
            ...this.pagination,
            page: res.pagination.currentPage,
            itemsPerPage: res.pagination.itemsPerPage,
            itemsLength: res.pagination.totalItems,
            pageStart:
              (res.pagination.currentPage - 1) * res.pagination.itemsPerPage,
            pageStop: res.pagination.currentPage * res.pagination.itemsPerPage,
            pageCount: Math.ceil(
              res.pagination.totalItems / res.pagination.itemsPerPage
            ),
          };
        })
        .finally(() => (this.isLoading = false));
    },
    formatFilters() {
      if (Object.entries(this.filters).length > 0) {
        return {
          modificationDateFrom: this.$moment.convertToQueryString(
            this.filters.modificationDate.from
          ),
          modificationDateTo: this.$moment.convertToQueryString(
            this.filters.modificationDate.to
          ),
          insertDateFrom: this.filters.creationDate
            ? this.$moment.convertToQueryString(this.filters.creationDate.from)
            : null,
          insertDateTo: this.filters.creationDate
            ? this.$moment.convertToQueryString(this.filters.creationDate.to)
            : null,
        };
      }
      return null;
    },
    showDetails(item) {
      this.$router.push({ name: "contactDetails", params: { id: item.id } });
    },
    _getCrmTypes() {
      return CrmCustomersService.GetCrmTypes().then((response) => {
        if (
          this.userRestrictions.some((restriction) => restriction.id === 401)
        ) {
          // Wszyskie typy (oprócz partnerów)
          this.types = response
            .filter((type) => type.code !== ContactTypes.Partner)
            .map((item) => {
              return { ...item, color: getContactTypeColors(item.code) };
            });
        } else {
          // Tylko klienci
          this.types = response
            .filter((type) => type.code === ContactTypes.Client)
            .map((item) => {
              return { ...item, color: getContactTypeColors(item.code) };
            });
        }
      });
    },
    _getCrmGroups() {
      return CrmCustomersService.GetCrmGroups().then(
        (res) => (this.groups = res)
      );
    },
    _getPagination(pagination = this.pagination) {
      return {
        pageNumber: pagination.page,
        pageSize: pagination.itemsPerPage,
      };
    },
    searchHandler(search) {
      this.pagination.page = 1;
      this.searchText = search;
    },
    _setFilters() {
      const groups = {
        type: "multiSelect",
        name: this.$t("contacts.group"),
        value: "groups",
        options: this.groups.map((g) => {
          return { name: g.text, value: g.id };
        }),
      };
      this.filterOptions.push(groups);
    },
    async deleteContacts() {
      this.isConfirmDeleteModalShown = false;

      this.selectedContacts.forEach((contact) => {
        if (!contact.canDelete) {
          contact.error = this.$t("contacts.unableToDelete");
        }
      });

      if (this.selectedContacts.every((c) => c.canDelete)) {
        await CrmCustomersService.deleteCrmCustomer(
          this.selectedContacts.map((x) => x.id)
        );
        this.selectedContacts = [];
        EmitSuccess(this.$t("contacts.deleteSuccess"));
        this.getContacts();
      } else {
        EmitError(this.$t("contacts.unableToDelete"));
      }
    },
    tryDelete() {
      if (this.selectedContacts.length > 0) {
        this.isConfirmDeleteModalShown = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;

  .date-filters {
    display: flex;
    flex-direction: row;

    .date-field {
      width: 210px;
      margin-left: 15px;
    }
  }
}

.toolbar-button {
  margin-right: 10px;
}

.contacts-button {
  margin-right: 15px;
}
</style>
