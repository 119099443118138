<template>
  <div class="selection-picker-wrapper">
    <div v-if="isMobile" class="mobile-selection-picker-container">
      <v-btn
        v-for="(option, index) in options"
        :key="index"
        @click="selectOption(option)"
        depressed
        :color="buttonColor(option)"
        :ripple="{ class: `${option.color}--text` }"
        class="mobile-selection-picker-item"
        :disabled="disabled"
      >
        <div class="mobile-selection-picker-item-content">
          <div v-if="option.icon">
            <IconBox
              :color="includes(option) ? 'selectionPickerSelectionItem' : null"
            >
              {{ `stem-state_${option.icon.toLowerCase()}` }}
            </IconBox>
            <span class="item-count">
              {{ formatNumber(option.count) }}
            </span>
          </div>
          <span
            :class="{ 'white--text': includes(option) }"
            :style="{ 'letter-spacing': '-0.14px' }"
          >
            {{ option.name }}
          </span>
        </div>
      </v-btn>
    </div>

    <v-flex v-if="!isMobile" class="selection-picker-container">
      <v-btn
        v-for="(option, index) in options"
        :key="index"
        @click="selectOption(option)"
        depressed
        :color="buttonColor(option)"
        :style="[
          !includes(option)
            ? {
                'box-shadow': `inset 0 -3px 0 ${option.color}`,
                background: 'transparent',
              }
            : {},
          { 'min-width': '120px' },
        ]"
        :ripple="{ class: `${option.color}--text` }"
        :disabled="disabled"
      >
        <div v-if="option.icon && windowWidth < maxWidth">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="picker-inner">
                <span :class="{ 'white--text': includes(option) }">
                  <IconBox
                    v-if="option.icon"
                    :color="
                      includes(option)
                        ? 'selectionPickerSelectionItem'
                        : option.color
                    "
                  >
                    {{ `stem-state_${option.icon.toLowerCase()}` }}
                  </IconBox>
                </span>
                <span
                  v-if="option.count !== undefined"
                  style="margin-left: 5px"
                  :class="includes(option) ? 'white--text' : ''"
                >
                  {{ formatNumber(option.count) }}
                </span>
              </div>
            </template>
            {{ option.name }}
          </v-tooltip>
        </div>

        <div v-else>
          <IconBox
            v-if="option.icon"
            :color="
              includes(option) ? 'selectionPickerSelectionItem' : option.color
            "
          >
            {{ `stem-state_${option.icon.toLowerCase()}` }}
          </IconBox>
          <span
            :class="{ 'white--text': includes(option) }"
            :style="{ 'letter-spacing': '-0.14px' }"
          >
            {{ option.name }}
          </span>
          <span
            v-if="option.count !== undefined"
            style="margin-left: 5px"
            :class="includes(option) ? 'white--text' : ''"
          >
            {{ formatNumber(option.count) }}
          </span>
        </div>
      </v-btn>
    </v-flex>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    options: {
      type: Array,
    },
    value: {
      type: Array,
    },
    color: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("settings", ["hasRestriction", "isMobile"]),
    selectedOptions: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      maxWidth: 1680,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleWindowResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
    },
    selectOption(option) {
      const options = [...this.selectedOptions];

      const index = options.findIndex((o) => o.code === option.code);
      if (index !== -1) {
        options.splice(index, 1);
      } else {
        options.push(option);
      }
      this.selectedOptions = options;
    },
    buttonColor(option) {
      if (this.color && this.includes(option)) {
        return this.color;
      }
      return this.includes(option) ? option.color : "";
    },
    includes(option) {
      return (
        this.selectedOptions.findIndex((o) => o.code === option.code) !== -1
      );
    },
  },
};
</script>

<style lang="scss" scoped>
$medium-screens: "only screen and (max-width : 1680px)";

.mobile-selection-picker-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .mobile-selection-picker-item {
    height: 94px;
    background: transparent;
    @media screen and (max-width: 776px) {
      min-width: 110px;
      height: 105px;
      margin: 10px 0;
    }

    &:not(:first-child) {
      margin-left: 10px;
    }

    .mobile-selection-picker-item-content {
      display: flex;
      flex-direction: column;

      .item-count {
        position: absolute;
        bottom: -23px;
        left: 50%;
        transform: translateX(-50%);
        background: white;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
        border-radius: 3px;
        padding: 3px 5px;
      }
    }
  }
}

.selection-picker-container {
  padding: 5px 0 10px 0;

  @media only screen and (max-width: 1000px) {
    width: 410px;
  }

  @media only screen and (min-width: 1000px) {
    width: 100%;
  }
  .picker-inner {
    display: flex;
    align-items: center;
  }
}

.selection-picker-container > * {
  margin-right: 10px;
  margin-top: 5px;
}
</style>
