<template>
  <!-- Tile -->
  <div v-if="display === displayListTypes.CARDS" class="tile-wrapper">
    <v-card
      :flat="!isSelected"
      height="140"
      min-width="425"
      class="tile"
      :style="{ borderLeft: `solid ${color}  5px` }"
      :class="{ errorBorder: error, mobile: this.isMobile }"
    >
      <v-layout column fill-height wrap>
        <v-flex>
          <v-card-text style="padding-bottom: 0">
            <div class="tile-content">
              <div class="tile-content-details" @click="$emit('click', 'card')">
                <v-layout justify-space-between fill-height wrap>
                  <v-flex xs6>
                    <TooltipTruncate :attrs="'subheading bold'">
                      {{ fullName }}
                    </TooltipTruncate>
                    <div :style="{ 'min-height': '15px' }">
                      <TooltipTruncate>
                        {{ groups | join }}
                      </TooltipTruncate>
                    </div>
                  </v-flex>

                  <v-flex xs6 text-right>
                    <TooltipTruncate>{{ email }}</TooltipTruncate>
                    <TooltipTruncate>{{ phone }}</TooltipTruncate>
                  </v-flex>
                  <v-flex my-1 xs12>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs6>
                    <span class="grey--text">{{ $t("common.date.add") }}:</span>
                    {{ insertDate | moment("displayDate") }}
                  </v-flex>

                  <v-flex xs6 text-right>
                    <span class="grey--text">
                      {{ $t("common.date.modify") }}:
                    </span>
                    {{ modificationDate | moment("displayDate") }}
                  </v-flex>
                </v-layout>
              </div>

              <div class="tile-content-buttons">
                <div class="select-field">
                  <v-checkbox
                    color="fields"
                    :input-value="isSelected"
                    @click.stop.prevent="$emit('select')"
                  />
                </div>
              </div>
            </div>
          </v-card-text>
        </v-flex>
      </v-layout>
    </v-card>
    <span class="error--text error-message" v-if="error">{{ error }}</span>
  </div>

  <!-- Inline -->
  <div v-else class="inline-wrapper">
    <v-card
      class="inline"
      :flat="!isSelected"
      :style="{ borderLeft: `solid ${color}  5px` }"
      :class="{ errorBorder: error }"
    >
      <v-card-text>
        <div class="inline-content">
          <v-layout
            overflow-hidden
            justify-space-between
            align-center
            class="inline-content-details textColor--text"
            @click="$emit('click')"
          >
            <v-flex xs3>
              <TooltipTruncate :attrs="'subheading bold'">{{
                fullName
              }}</TooltipTruncate>
            </v-flex>
            <v-flex xs2>
              <span class="label">{{ $t("contacts.group") }}</span>
              <TooltipTruncate>{{ groups | join }}</TooltipTruncate>
            </v-flex>
            <v-flex xs2>
              <span class="label">{{ $t("newContact.email") }}</span>
              <TooltipTruncate>{{ email }}</TooltipTruncate>
            </v-flex>
            <v-flex xs1>
              <span class="label">{{ $t("newContact.phone") }}</span>
              <TooltipTruncate>{{ phone }}</TooltipTruncate>
            </v-flex>
            <v-flex xs1>
              <span class="label">{{ $t("common.created") }}</span>
              <div>{{ insertDate | moment("displayDate") }}</div>
            </v-flex>
            <v-flex xs1>
              <span class="label">{{ $t("contacts.modificationDate") }}</span>
              <div>{{ modificationDate | moment("displayDate") }}</div>
            </v-flex>
          </v-layout>

          <div class="inline-content-buttons">
            <div class="select-field">
              <v-checkbox
                color="fields"
                :input-value="isSelected"
                @click.stop.prevent="$emit('select')"
              />
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <span class="error--text error-message" v-if="error">{{ error }}</span>
  </div>
</template>

<script>
import { emailValidator, phoneValidator } from "@/mixins/validators.js";
import DisplayListTypes from "@/models/displayListTypes.js";
import { mapGetters } from "vuex";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    groups: {
      type: Array,
    },
    name: {
      type: String,
      required: true,
    },
    surname: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      validator: emailValidator,
    },
    phone: {
      type: String,
      validator: phoneValidator,
    },
    insertDate: {
      type: String,
    },
    modificationDate: {
      type: String,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    display: {
      type: String,
      default: DisplayListTypes.CARDS,
    },
    color: {
      type: String,
      default: "grey",
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      displayListTypes: DisplayListTypes,
    };
  },
  computed: {
    ...mapGetters("settings", ["hasRestriction", "isMobile"]),
    fullName() {
      return `${this.surname} ${this.name}`;
    },
  },
};
</script>

<style scoped lang="scss">
.tile {
  .tile-content {
    .tile-content-details {
      cursor: pointer;
    }
  }

  .tile-content-buttons {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    height: 30px;

    .select-field {
      margin-left: auto;

      .v-input--selection-controls.v-input {
        margin: 0;
      }
    }
  }
}

.inline {
  .inline-content {
    display: flex;
    flex-direction: row;

    .inline-content-details {
      cursor: pointer;
      margin-right: 10px;

      .label {
        color: $label;
      }
    }
  }
}

.tile:hover,
.inline:hover {
  background: $card-hover;
}

.tile-wrapper,
.inline-wrapper {
  box-shadow: $card-shadow;
}

.error-message {
  font-size: 13px;
}

.errorBorder {
  box-shadow: inset 0 0 0 2px $error;
}
</style>
